<template>
    <v-form class="row" v-model="validForm" ref="form">
        <v-col cols="12" md="10" lg="6">
            <v-text-field required persistent-placeholder label="Omschrijving" type="text"
                v-model="modelValue.description"
                :rules="getRequiredRules('Omschrijving moet ingevuld worden')"></v-text-field>
        </v-col>
        <v-col cols="12" md="1" lg="1">
            <v-text-field required persistent-placeholder label="BTW (%)" type="number" v-model="modelValue.vat"
                :rules="[...getMinimalNumberRules('BTW moet minstens 0 zijn', 0), ...getRequiredRules('BTW moet ingevuld worden')]"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="1">
            <v-text-field required persistent-placeholder label="Eenheidsprijs (€)" type="number"
                v-model="modelValue.priceByHour"
                :rules="getRequiredRules('Eenheidsprijs moet ingevuld worden')"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="1" class="ga-0" color="green">
            <v-row no-gutters align="center">
                <v-col cols="6">
                    <v-text-field required persistent-placeholder label="Aantal" type="number"
                        v-model="computedRegisteredHours"
                        :rules="[ ...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
                    </v-text-field>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" md="2" lg="1">
            <v-text-field persistent-placeholder disabled label="Gefactureerd bedrag" type="text"
                :value="getFormatedTotal"></v-text-field>
        </v-col>
        <v-col cols="12" md="1" lg="1">
            <v-btn fab small elevation="1" @click="onRemove(modelValue)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </v-col>
    </v-form>
</template>
<script>
import { getRequiredRules, getBiggerThanZeroRules, getMinimalNumberRules } from '@/shared/utils/inputRulesUtils.js';
import { VInput } from 'vuetify/lib';
import InvoiceLineType from "@/shared/enums/invoiceLineType";

export default VInput.extend({
    name: "ClientInvoiceLineDescription",
    props: {
        value: {
            type: Object,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        onRemove: {
            type: Function,
            default: () => { }
        },
        rules: {
            type: Array,
            //Act as an input, parent form is only valid when this form is valid too
            default() {
                return [() => {
                    if (this.isNew) {
                        return true
                    }
                    return this.validForm
                }]
            },
        },
    },
    emits: ['update:value'],
    data() {
        return {
            InvoiceLineType: InvoiceLineType,
            user: null,
            vat: null,
            priceByHour: null,
            validForm: false

        }
    },
    components: {

    },
    methods: {
        getMinimalNumberRules,
        getRequiredRules,
        getBiggerThanZeroRules,
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update:value', newValue)
            }
        },
        computedRegisteredHours: {
            get() {
                // Allow for the number 0 to be displayed
                if (this.modelValue?.reportedMinutes === 0) {
                    return 0
                }
                return  !this.modelValue?.reportedMinutes ? null : this.modelValue?.reportedMinutes / 60
            },
            set(newValue) {
              if (newValue === 0) {
                return this.$set(this.modelValue, "reportedMinutes", 0)
              }
              if (!newValue) {
                return this.$set(this.modelValue, "reportedMinutes", null)
              }
                this.$set(this.modelValue, "reportedMinutes", newValue * 60)
            }
        },
        getFormatedTotal() {
            if (
                this.modelValue.reportedMinutes !== null
                && this.modelValue.reportedMinutes !== undefined

                && this.modelValue.vat !== null
                && this.modelValue.vat !== undefined

                && this.modelValue.priceByHour !== null
                && this.modelValue.priceByHour !== undefined
            ) {
                return `€${((this.modelValue.reportedMinutes / 60) * this.modelValue.priceByHour * (this.modelValue.vat / 100 + 1)).toFixed(2)}`
            } else
            // Allow for the number 0 to be displayed
            {
                return null
            }

        },
    },
    watch: {
        validForm: {
            immediate: true,
            handler() {
                this.validate()
            }
        }
    }
})
</script>