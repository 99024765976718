<template>
    <v-form class="row" v-model="validForm" ref="form">
        <v-col cols="12" md="4" lg="2">
            <CustomUserSelect required persistent-placeholder cols="12" v-model="user" @update="setUserId"
                :autoFillUserId="modelValue.userId" />
        </v-col>
        <CustomClientAndProjectSelect persistent-placeholder cols="12" md="8" lg="4" required v-model="clientAndProject"
            :horizontal="$vuetify.breakpoint.mdAndUp" :disabled="!modelValue.userId"
            :autoFillClientId.sync="modelValue.clientId" :autoFillProjectId.sync="modelValue.projectId" />
        <v-col cols="12" md="1" lg="1">
            <v-text-field required persistent-placeholder label="BTW (%)" type="number" v-model="modelValue.vat"
                :rules="[...getMinimalNumberRules('BTW moet minstens 0 zijn', 0), ...getRequiredRules('BTW moet ingevuld worden')]"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="1">
            <v-text-field required persistent-placeholder label="Bedrag per uur (€)" type="number"
                v-model="modelValue.priceByHour"
                :rules="getRequiredRules('Bedrag per uur moet ingevuld worden')"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="1" class="ga-0" color="green">
            <v-row no-gutters align="center">
                <v-col cols="6">
                    <v-text-field persistent-placeholder label="uren" type="number"
                        :value="computedRegisteredHours.hours"
                        @input="(newValue) => computedRegisteredHours = { ...computedRegisteredHours, hours: Number(newValue) }">
                    </v-text-field>
                </v-col>
                <v-col cols="5">
                    <v-text-field persistent-placeholder label="minuten" type="number"
                        :value="computedRegisteredHours.minutes"
                        @input="(newValue) => computedRegisteredHours = { ...computedRegisteredHours, minutes: Number(newValue) }"></v-text-field>
                </v-col>
                <v-col cols="1">
                    <v-icon v-if="hasCustomReportedMinutes" @click="clearCustomDuration"
                        title="terug naar automatisch berekende uren" color="red">
                        mdi-undo
                    </v-icon>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" md="2" lg="1">
            <v-text-field persistent-placeholder disabled label="Gefactureerd bedrag" type="text"
                :value="getFormatedTotal"></v-text-field>
        </v-col>
        <v-col cols="12" md="1" lg="1">
            <v-btn title="lijn toevoegen" v-if="isNew" fab small elevation="1" @click="onAdd(modelValue)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn v-else fab small elevation="1" @click="onRemove(modelValue)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </v-col>
    </v-form>
</template>
<script>
import { getRequiredRules, getMinimalNumberRules } from '@/shared/utils/inputRulesUtils.js';
import CustomUserSelect from "@/components/shared/CustomUserSelect.vue";
import CustomClientAndProjectSelect from '@/components/shared/CustomClientAndProjectSelect.vue';
import { splitMinutesToDuration } from "@/shared/utils/dateUtils";
import { VInput } from 'vuetify/lib';
import InvoiceLineType from "@/shared/enums/invoiceLineType";

export default VInput.extend({
    name: "ClientInvoiceLine",
    model: {        
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: Object,
        isNew: {
            type: Boolean,
            default: false,
        },
        onAdd: {
            type: Function,
            default: () => { }
        },
        onRemove: {
            type: Function,
            default: () => { }
        },
        invoicePeriodFrom: {
            type: String,
            default: null
        },
        invoicePeriodUntil: {
            type: String,
            default: null
        },
        rules: {
            type: Array,
            //Act as an input, parent form is only valid when this form is valid too
            default() {
                return [() => {
                    if (this.isNew) {
                        return true
                    }
                    return this.validForm
                }]
            },
        },
    },
    emits: ['update:value'],
    data() {
        return {
            clientAndProject: {
                client: null,
                project: null,
            },
            InvoiceLineType: InvoiceLineType,
            user: null,
            vat: null,
            priceByHour: null,
            calculatedInvoiceLine: {},
            validForm: false,
        }
    },
    components: {
        CustomUserSelect,
        CustomClientAndProjectSelect
    },
    methods: {
        getMinimalNumberRules,
        getRequiredRules,
        setClientAndProjectIds(newValue) {
            if (newValue?.client) {
                this.modelValue.clientId = newValue.client.id
                this.modelValue.clientName = newValue.client.clientName
            }
            if (newValue?.project) {
                this.modelValue.projectId = newValue.project.id
                this.modelValue.projectName = newValue.project.projectName
            }
        },
        setUserId(newUser) {
            if (newUser) {
                this.$set(this.modelValue, 'userId',  newUser?.id)
                this.$set(this.modelValue, 'userName',  [newUser.firstName, newUser.lastName].filter(Boolean).join(' '))
            }
        },
        calculateInvoiceLine() {
            if (
                this.getCalculationPayload.clientId != null
                && this.getCalculationPayload.projectId != null
                && this.invoicePeriodFrom != null
                && this.invoicePeriodUntil != null
                && this.validForm
            ) {
                this.$store.dispatch("invoicesModule/fetchCalculatedInvoiceLine", this.getCalculationPayload)
                    .then(result => {
                        this.calculatedInvoiceLine = result;
                        if (!this.hasCustomReportedMinutes) {
                            this.$set(this.modelValue, "reportedMinutes", this.calculatedInvoiceLine.reportedMinutes)
                        }
                    })
            }
        },
        clearCustomDuration() {
            this.$set(this.modelValue, "reportedMinutes", this.calculatedInvoiceLine.reportedMinutes)
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update:value', newValue)
            }
        },
        hasCustomReportedMinutes() {
            return this.modelValue?.reportedMinutes !== null
                && this.modelValue?.reportedMinutes !== undefined
                && this.calculatedInvoiceLine?.reportedMinutes !== null
                && this.calculatedInvoiceLine?.reportedMinutes !== undefined
                && this.calculatedInvoiceLine?.reportedMinutes !== this.modelValue.reportedMinutes
        },
        computedRegisteredHours: {
            get() {
                if (this.hasCustomReportedMinutes) {
                    return splitMinutesToDuration(this.modelValue.reportedMinutes)
                }
                // Allow for the number 0 to be displayed
                if (
                    (this.calculatedInvoiceLine?.reportedMinutes === null || this.calculatedInvoiceLine?.reportedMinutes === undefined)
                    &&
                    (this.modelValue?.reportedMinutes === null || this.modelValue?.reportedMinutes === undefined)
                ) {
                    return {
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    }
                }
                return splitMinutesToDuration(this.calculatedInvoiceLine.reportedMinutes)
            },
            set(newValue) {
                this.$set(this.modelValue, "reportedMinutes", (newValue.hours * 60) + newValue.minutes)
            }
        },
        getFormatedTotal() {
            if (this.modelValue.reportedMinutes !== null && this.modelValue.reportedMinutes !== undefined) {
                return `€${((this.modelValue.reportedMinutes / 60) * this.modelValue.priceByHour * (this.modelValue.vat / 100 + 1)).toFixed(2)}`
            }
            // Allow for the number 0 to be displayed
            if (this.calculatedInvoiceLine?.total === null || this.calculatedInvoiceLine?.total === undefined) {
                return null
            }
            return `€${this.calculatedInvoiceLine.total.toFixed(2)}`
        },
        getCalculationPayload() {
            return {
                userId: this.modelValue.userId,
                userName: this.modelValue.userName,
                clientId: this.modelValue.clientId,
                projectId: this.modelValue.projectId,
                clientName: this.modelValue.clientName,
                projectName: this.modelValue.projectName,
                vat: this.modelValue.vat,
                priceByHour: this.modelValue.priceByHour,
                invoicePeriodFrom: this.invoicePeriodFrom,
                invoicePeriodUntil: this.invoicePeriodUntil
            }
        }
    },
    watch: {
        getCalculationPayload: {
            immediate: true,
            deep: true,
            handler(newValue) {
                // Only when form is valid
                if (this.$refs["form"]?.validate()) {
                    this.calculateInvoiceLine(newValue, this.invoicePeriodFrom, this.invoicePeriodUntil)
                }
            }
        },
        validForm: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.calculateInvoiceLine(this.getCalculationPayload, this.invoicePeriodFrom, this.invoicePeriodUntil)
                }
                this.validate()
            }
        },
        clientAndProject: {
            deep: true,
            handler(newValue) {
                this.setClientAndProjectIds(newValue)
            }
        },
    }
})
</script>
